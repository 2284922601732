'use client'; // Error components must be Client Components
import { GlobalErrorMessage } from '@wla/app/global-error-message';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { useEffect } from 'react';

export default function Error({ error }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    trackError(error);
  }, [error]);

  return <GlobalErrorMessage />;
}
